<template>
  <div class="act_body_all">
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="act_btn">
        <div class="act_title">律师申请管理</div>
<!--        <div class="act_btn_all">-->
<!--          <el-button type="success" @click="addActivity" round-->
<!--            >添加活动</el-button-->
<!--          >-->
<!--        </div>-->
      </div>
      <div style="width: 90%; margin-top: 10px">
        <div
          style="
            display: flex;
            align-items: center;
            margin-top: 20px;
            justify-content: end;
            margin-bottom: 10px;
          "
        >
          <el-button type="primary" @click.prevent="encyclopediasInfo()" class="a">刷新</el-button>
          <div class="act_search">
            <el-input
              style="width: 220px"
              v-model="input"
              placeholder="搜索关键字"
              clearable
            />
            <el-button type="primary" @click.prevent="Search()">搜索</el-button>
          </div>
        </div>

        <el-table :data="data.actInfo1" height="451px" style="width: 100%">
          <el-table-column prop="name" label="姓名" />
          <el-table-column prop="licenseNumber" label="律师执业证号" />
          <el-table-column prop="lawofficeName" label="请输入执业律所名称" />
          <el-table-column prop="lawofficeLocal" label="请输入执业律所所在地" />
          <el-table-column prop="phoneNumber" label="手机号" />
          <el-table-column prop="picturePrice" label="图文咨询价格" />
          <el-table-column prop="voicePrice" label="语音咨询价格" />
          <el-table-column prop="skilledField" label="擅长领域" />
          <el-table-column prop="identity" label="身份" />
          <el-table-column prop="oneselfPicture" label="证件照">
            <template v-slot="{ row }">
              <div class="demo-image__preview">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="row.oneselfPictureUrl"
                  :preview-src-list="[row.oneselfPictureUrl]"
                  :zoom-rate="1.2"
                  :max-scale="7"
                  :min-scale="0.2"
                  fit="cover"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="practicingCertificate" label="执业证照片">
            <template v-slot="{ row }">
              <div class="demo-image__preview">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="row.practicingCertificateUrl"
                  :zoom-rate="1.2"
                  :preview-src-list="[row.practicingCertificateUrl]"
                  :max-scale="7"
                  :min-scale="0.2"
                  fit="cover"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120px">
            <template #default="scope">
              <div style="display: flex">
                <el-button
                  type="primary"
                  size="small"
                  @click="editRow(scope.row)"
                >
                  通过
                </el-button>
                <el-button
                  type="danger"
                  size="small"
                  @click.prevent="deleteRow(scope.row)"
                  >不通过</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="demo-pagination-block">
        <el-pagination
          v-model:currentPage="currentPage4"
          :page-sizes="[10, 20, 30, 40]"
          v-model:page-size="data.page_size"
          v-model:current-page="data.page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="data.actInfo.length"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted, reactive } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "activity",
  setup() {
    const formLabelWidth = "140px";
    const { proxy } = getCurrentInstance();
    const input = ref("");
    const currentPage4 = ref(4);
    const value = ref("");
    const data = reactive({
      page: 1,
      page_size: 10,
      actInfo: [],
      actInfo1: [],
    });
    onMounted(() => {
      encyclopediasInfo();
    });
    function addActivity() {
      proxy.$router.push("/activityAdd"); //切换路由
    }
    function Search() {
      proxy.$http
        .get("/ActiveController/queryActive", {
          Keyword: input.value,
        })
        .then((res) => {
          console.log(res);
          data.actInfo = res.data;
          getPageData(); //分页
        });
    }
    function getPageData() {
      let start = (data.page - 1) * data.page_size;
      let end = start + data.page_size;
      data.actInfo1 = data.actInfo.slice(start, end);
      // console.log(start)
      // console.log(end)
      console.log(data.actInfo1)
    }
    function handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      data.page_size = val;
      getPageData();
    }
    function handleCurrentChange(val) {
      data.page = val;
      console.log(val);
      getPageData();
    }
    function encyclopediasInfo() {
      proxy.$http.get("/lawyer/queryCheckLawyerList", {}).then((res) => {
        console.log(res);
        data.actInfo = res.data.map(item => {
          return {
            ...item,
            oneselfPictureUrl: item.oneselfPicture,
            practicingCertificateUrl: item.practicingCertificate,
          }
        });
        getPageData(); //分页
        console.log(data);
      });
    }

    function editRow(row) {
      console.log(row);
      ElMessageBox.confirm("此操作通过该用户的申请, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          proxy.$http
            .get("/lawyer/checkLawyer", {
              lawyerId: row.openid,
            })
            .then((res) => {
              console.log(res);
              if (res.code === 20000) {
                ElMessage({
                  type: "success",
                  message: res.msg,
                });
                encyclopediasInfo();
              } else
                ElMessage({
                  type: "error",
                  message: res.msg,
                });
            });
        })
        .catch(() => {
          ElMessage({
            type: "error",
            message: "撤销操作",
          });
        });
    }
    function deleteRow(row) {
      console.log(row);
      ElMessageBox.confirm("此操作拒绝该用户的申请, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          proxy.$http
            .get("/lawyer/checkLawyerNo", {
              lawyerId: row.openid,
            })
            .then((res) => {
              console.log(res);
              if (res.code === 20000) {
                ElMessage({
                  type: "success",
                  message: res.msg,
                });
                encyclopediasInfo();
              } else
                ElMessage({
                  type: "error",
                  message: res.msg,
                });
            });
        })
        .catch(() => {
          ElMessage({
            type: "error",
            message: "撤销操作",
          });
        });
    }
    return {
      input,
      currentPage4,
      data,
      value,
      Search,
      encyclopediasInfo,
      editRow,
      deleteRow,
      handleSizeChange,
      handleCurrentChange,
      addActivity,
      formLabelWidth,
    };
  },
};
</script>

<style scoped>
.act_body_all {
  background-color: white;
  padding: 20px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.act_title {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 2px;
  margin: 20px 20px 10px 20px;
}
.act_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.act_btn_all {
  margin-right: 60px;
}
.act_search {
  display: flex;
  justify-content: end;
}
.demo-pagination-block {
  margin-top: 10px;
}
/*隐藏滚动条*/
.el-table__body-wrapper {
  padding-right: 20px;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
.demo-image__error .image-slot {
  font-size: 30px;
}
.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}
.demo-image__error .el-image {
  width: 100%;
  height: 200px;
}
</style>
